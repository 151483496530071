@font-face {
  font-family: Roboto;
  src: url("./fonts/roboto/Roboto-Regular.ttf");
  font-weight: 400
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/roboto/Roboto-Black.ttf");
  font-weight: 900
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/roboto/Roboto-Bold.ttf");
  font-weight: 700
}


@font-face {
  font-family: Roboto;
  src: url("./fonts/roboto/Roboto-Medium.ttf");
  font-weight: 500
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/roboto/Roboto-Light.ttf");
  font-weight: 300
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/roboto/Roboto-Thin.ttf");
  font-weight: 100
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.text-stroked {
  -webkit-text-stroke: 2px var(--text-color);
  color: transparent;
}



@media (min-width: 1024px) {
  .text-stroked {
    -webkit-text-stroke: 4px var(--text-color);
  }
}


.underline-animation {
  display: inline-block;
  padding-bottom: 2px;
  background-image: linear-gradient(var(--primary-color) 0 0);
  background-position: 0 100%;
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition:
    background-size 0.3s,
    background-position 0s 0.3s;
}



.underline-animation:hover {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

.permanent-underline {
  display: inline-block;
  padding-bottom:2px;
  background-image: linear-gradient(var(--primary-color) 0 0);
  background-position: right bottom; 
  background-size: 100% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.5s;
}

.permanent-underline:hover {
  background-size: 0% 2px;
}


.theme-dark {
  --primary-color: #ffffff;
  --secondary-color: #777777;
  --gradient-first:  #0f172a;
  --gradient-second: #334155;
  --text-color: #ffffff;
  --triangle-color: #ffffff;
  --project-title-color: #334155;


}

.theme-darkness {
  --primary-color: #ffffff;
  --secondary-color: #777777;
  --gradient-first:  #1e2124;
  --gradient-second: #3b3f46;
  --text-color: #ffffff;
  --triangle-color: #ffffff;
  --project-title-color: #3b3f46;

}

.theme-grayscale {
  --primary-color: #ffffff;
  --secondary-color: #ffffff;
  --gradient-first: #a9a9aa;
  --gradient-second: #1e2124;
  --text-color: #ffffff;
  --triangle-color: #ffffff;
  --project-title-color: #1e2124;

}

.theme-mirage {
  --primary-color: #3A6073;
  --secondary-color: #777777;
  --gradient-first:  #16222A;
  --gradient-second: #3A6073;
  --text-color: #ffffff;
  --triangle-color: #ffffff;
  --project-title-color: #3A6073;


}


.theme-twilight {
  --primary-color: #1446A0;
  --secondary-color: #ffffff;
  --gradient-first: #f59e0b;
  --gradient-second: #ec4899;
  --text-color: #ffffff;
  --triangle-color: #ffffff;
  --project-title-color: #ec4899;

}


.theme-pizelex {
  --primary-color: #ffffff;
  --secondary-color: #ffffff;
  --gradient-first: #114357;
  --gradient-second: #F29492;
  --text-color: #ffffff;
  --triangle-color: #ffffff;
  --project-title-color: #F29492;

}

.theme-metapolis {
  --primary-color: #ffffff;
  --secondary-color: #ffffff;
  --gradient-first: #659999;
  --gradient-second: #f4791f;
  --text-color: #ffffff;
  --triangle-color: #ffffff;
  --project-title-color: #f4791f;

}


